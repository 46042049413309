((_window, _document) => {
  const embedContainer = _document.querySelector('.jotform-table-embed');

  const tableID = embedContainer.getAttribute('data-id');
  const iframeSource = embedContainer.hasAttribute('data-iframesource') ? embedContainer.getAttribute('data-iframesource') : 'www.jotform.com';

  const iframe = _document.createElement('iframe');

  iframe.src = `https://${iframeSource}/tables/${tableID}?embedMode=js`;
  iframe.scrolling = 'no';
  iframe.frameborder = 0;
  iframe.style.border = 'none';
  iframe.style.borderRadius = '6px';
  iframe.setAttribute('allowFullScreen', '');
  iframe.width = '100%';
  iframe.height = '100%';

  embedContainer.appendChild(iframe);
})(window, document);
